<template>
  <div
    class="flex flex-col gap-y-4"
    data-testid="block-content"
  >
    <div
      v-for="(block, index) in post.blocks"
      :key="index"
      data-allow-mismatch="children"
    >
      <PaywallContainer
        v-if="showPaywall && index === paywallPos"
        class="pb-4 content-block"
        :promotion-paywall="paywallSettings?.isPromotionPaywall ?? false"
        :trigger-manual="paywallSettings?.triggerManual ?? false"
        :paywall-as-modal="paywallSettings?.paywallAsModal ?? false"
      />

      <GdprBlock
        v-if="block.t === 'GdprBlock'"
        v-bind="$attrs"
        class="content-block"
        :provider="getProvider(block.a || [])"
      />

      <CustomIframe
        v-else-if="isCustomIframe(block) && block.a"
        class="content-block"
        :data="block.a"
      />

      <BrightcovePlayerWrapper
        v-else-if="block.ot === 'custom/brightcove'"
        class="content-block--wide"
        :attrs="block.a"
      />

      <DcxImage
        v-else-if="block.ot === 'custom/dcx-image'"
        class="content-block--wide"
        :attrs="block.a"
      />

      <QuoteBlock
        v-else-if="block.t === 'QuoteBlock'"
        class="content-block"
        :content="block.h"
      />

      <SnowReportBlock
        v-else-if="block.ot === 'russmedia/snow-report'"
        class="content-block"
        :block="block"
        :content="block.h"
        v-bind="$attrs"
      />

      <Gallery
        v-else-if="block.ot === 'core/gallery'"
        class="content-block--wide"
        :block="block"
        v-bind="$attrs"
      />

      <ReaderSuggestionsBlock
        v-else-if="isReaderSuggestionBlock(block)"
        class="content-block"
        :block="block"
        :content="block.h"
        v-bind="$attrs"
      />

      <HtmlBlock
        v-else-if="block.t === 'HtmlBlock'"
        :class="getHtmlBlockClass(block)"
        :block="block"
        :content="block.h"
        v-bind="$attrs"
      />

      <template v-else-if="block.t === 'VueBlock'">
        <component
          :is="(block as Block<'VueBlock'>).a?.component"
          v-bind="(block as Block<'VueBlock'>).a?.props"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GdprConsent } from '@russmedia-frontend/nordstern-embeds'
import {
  GranularConsent,
  handleEmbeds,
  handleGdprForBlock,
} from '@russmedia-frontend/nordstern-embeds'
import { computed, onMounted, watch } from 'vue'
import { useGdpr } from '../../composables/useGdpr'
import GdprBlock from '../GdprBlock.vue'
import BrightcovePlayerWrapper from './BrightcovePlayerWrapper.vue'
import CustomIframe from './CustomIframe.vue'
import DcxImage from './DcxImage.vue'
import Gallery from './Gallery.vue'
import HtmlBlock from './HtmlBlock.vue'
import PaywallContainer from './PaywallContainer.vue'
import QuoteBlock from './QuoteBlock.vue'
import ReaderSuggestionsBlock from './ReaderSuggestionsBlock.vue'
import SnowReportBlock from './SnowReportBlock.vue'

const props = withDefaults(
  defineProps<{
    blocks?: Block[]
    gdprSettings?: GdprConsent
    showPaywall?: boolean
    paywallPos?: number
    paywallSettings?: any
  }>(),
  {
    blocks: () => [],
    gdprSettings: () =>
      ({
        necessary: false,
        preferences: false,
        marketing: false,
        statistics: false,
        granular: {},
      }) as GdprConsent,
    showPaywall: false,
    paywallPos: 1,
    paywallSettings: {
      isPromotionPaywall: false,
      paywallAsModal: false,
      triggerManual: false,
    },
  },
)

useGdpr().setSettings(props.gdprSettings)

const post = computed(() => ({ blocks: props.blocks.map((block: Block) => {
  return handleGdprForBlock(block, props.gdprSettings)
}) }))

function isCustomIframe(block: Block) {
  return ['custom/iframe', 'custom/glomex'].includes(block.ot || '')
}

function isReaderSuggestionBlock(block: Block) {
  return (block.h || '').includes('reusable-reader-suggestions')
}

function getProvider(attrs: BlockAttribute[]) {
  if (attrs.length > 0) {
    return attrs.find((attr: BlockAttribute) => attr.key === 'providerNameSlug')
      ?.value || GranularConsent._generic
  }

  return GranularConsent._generic
}

function getHtmlBlockClass(block: Block) {
  if (['core/image'].includes(block.ot || '')) {
    return 'content-block--wide'
  }

  return 'content-block'
}

watch(
  () => props.gdprSettings,
  (settings: GdprConsent) => {
    useGdpr().setSettings(settings)

    // @ts-expect-error: Disable type checking for the next line
    handleEmbeds(post.value)
  },
)

onMounted(() => {
  // @ts-expect-error: Disable type checking for the next line
  handleEmbeds(post.value)
})
</script>
