<template>
  <div>
    <ReaderSuggestionsSlim
      v-if="reusableBlock"
      :api-key="reusableBlock?.dataset?.apiKey ?? ''"
      :api-url="reusableBlock?.dataset?.apiUrl ?? ''"
      :prefill-email="user?.email ?? ''"
      :prefill-name="user?.fullname ?? ''"
      v-bind="dataAttrs"
    >
      <template #collapsed-button-content>
        <div v-html="blockString" />
      </template>
    </ReaderSuggestionsSlim>
  </div>
</template>

<script setup lang="ts">
import { ReaderSuggestionsSlim } from '@russmedia-frontend/nordstern-reader-suggestions'
import { useSSO } from '@russmedia-frontend/np-common'
import { onMounted, ref } from 'vue'

const props = defineProps<{
  block: Block
}>()
const user = useSSO().getSSO()
const reusableBlock = ref<HTMLElement | null>(null)
const blockString = props.block.h
const dataAttrs = ref({})

function convertBooleans(dataset: DOMStringMap): Record<string, any> {
  return Object.entries(dataset).reduce((acc, [key, value]) => {
    acc[key] = value === 'true' ? true : value === 'false' ? false : value
    return acc
  }, {} as Record<string, any>)
}

onMounted(() => {
  const container = document.createElement('div')
  container.innerHTML = blockString as string
  reusableBlock.value = container.querySelector('.reusable-reader-suggestions')

  if (reusableBlock.value) {
    dataAttrs.value = convertBooleans(reusableBlock.value.dataset)
  }
})
</script>
