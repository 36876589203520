<template>
  <div v-if="promotionPaywall" class="vodl-promotion-subscription">
    <template v-if="!showAlreadySubscribed">
      <DefaultButton
        v-if="showIapButton"
        color="Red"
        @click="onIapButtonClicked"
      >
        {{ buttonText }}
      </DefaultButton>

      <div v-else-if="!params.noheader" class="vodl-paywall" />
    </template>

    <div v-if="showAlreadySubscribed">
      <h2 class="typo-artikel-sub">Du hast bereits einen V+ Zugang</h2>
      <span class="typo-basics-1">
        <a class="underline text-grau-600 hover:text-text" href="/abo">Hier</a>
        kannst du deine Zugänge verwalten.
      </span>
    </div>
  </div>

  <div v-else class="vodl-paywall" />
</template>

<script setup lang="ts">
import { DefaultButton, useSubscription } from '@russmedia-frontend/np-common'
import { useUrlSearchParams } from '@vueuse/core'
import { onMounted, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    promotionPaywall?: boolean
    triggerManual?: boolean
    paywallAsModal?: boolean
  }>(),
  {
    promotionPaywall: false,
    triggerManual: false,
    paywallAsModal: false,
  },
)

interface ExtWindow extends Window {
  RussMedia: any
}

declare let window: ExtWindow

const params = useUrlSearchParams('history')

const showAlreadySubscribed = ref(false)
const showIapButton = ref(false)
const buttonText = ref('')

function onLoginResolved(ev: any) {
  const userData = ev.detail || {}
  userData.piano = userData.piano || {}
  userData.piano.abo = userData.piano.abo || {}

  if (
    useSubscription().isPremiumUser(userData.piano.abo)
    && !props.paywallAsModal
  ) {
    showAlreadySubscribed.value = true
  }
}

function onIapButtonClicked() {
  document.dispatchEvent(new Event('vodl-iap-button-clicked'))
}

function showIapOfferButton(priceText: string) {
  if (!priceText) {
    return
  }

  buttonText.value = priceText
  showIapButton.value = true
}

onMounted(() => {
  window.RussMedia = window.RussMedia || {}
  window.RussMedia.Piano = window.RussMedia.Piano || {}
  window.RussMedia.Piano.showIapOfferButton = showIapOfferButton

  // Directly dispatch iap offer button clicked event if the paywall is displayed as a modal.
  if (params.noheader && !props.triggerManual && props.paywallAsModal) {
    onIapButtonClicked()
  }

  window.addEventListener('vodl_piano_on_login_resolved', onLoginResolved)
})
</script>
