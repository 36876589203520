<template>
  <div>
    <ImageGallery
      v-if="imageData.length > 1 && !showLightbox"
      class="max-w-full -mx-4 sm:mx-auto"
      :title="title"
      :images="imageData"
      :inline="true"
      :initial-slide="activeIndex"
      @fullscreen-clicked="showLightbox = true"
      @slide-changed="onSlideChange"
    />
    <figure v-else-if="imageData?.img" class="-mx-4 sm:mx-auto">
      <ResponsivePicture
        :picture="imageData"
        additional-class="image-block-picture"
      />
      <figcaption
        v-if="imageData?.img.caption"
        class="px-4 mt-4 typo-meta-1 sm:px-0"
        v-html="imageData.img.caption"
      />
    </figure>
    <Teleport to="body">
      <Lightbox v-if="showLightbox" @close="showLightbox = false">
        <ImageGallery
          :title="title"
          :images="imageData"
          :inline="false"
          :initial-slide="activeIndex"
          @slide-changed="onSlideChange"
        />
      </Lightbox>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import {
  ImageGallery,
  Lightbox,
  ResponsivePicture,
} from '@russmedia-frontend/np-common'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    attrs?: BlockAttribute[]
  }>(),
  {
    attrs: () => [],
  },
)

const showLightbox = ref(false)
const activeIndex = ref(0)

const imageData = computed(() => {
  const data = props.attrs.find((attr: BlockAttribute) => attr.key === 'images')

  if (!data) {
    return []
  }

  try {
    const parsedImages = JSON.parse(data.value ?? '')
    const validImages = parsedImages.filter((image: any) => image.src)

    return validImages.length > 1 ? validImages : { img: validImages[0] }
  }
  catch {
    return []
  }
})

const title = computed(
  () =>
    props.attrs.find((attr: BlockAttribute) => attr.key === 'galleryTitle')
      ?.value || '',
)

function onSlideChange(index: number) {
  activeIndex.value = index - 1
}
</script>
