<template>
  <div>
    <ImageGallery
      v-if="figures.length && !showLightbox"
      class="max-w-full rounded-lg wp-block-gallery sm:mx-auto"
      :title="title"
      :inline="true"
      :initial-slide="activeIndex"
      :slides-total="slidesTotal"
      @fullscreen-clicked="showLightbox = true"
      @slide-changed="onSlideChange"
    >
      <template #slides>
        <swiper-slide v-for="(figure, index) in figures" :key="index">
          <div class="w-full h-full" v-html="figure" />
        </swiper-slide>
      </template>
    </ImageGallery>
    <Teleport to="body">
      <Lightbox v-if="showLightbox" @close="showLightbox = false">
        <ImageGallery
          class="wp-block-gallery"
          :title="title"
          :inline="false"
          :initial-slide="activeIndex"
          :slides-total="slidesTotal"
          @slide-changed="onSlideChange"
        >
          <template #slides>
            <swiper-slide v-for="(figure, index) in figures" :key="index">
              <div class="w-full h-full" v-html="figure" />
            </swiper-slide>
          </template>
        </ImageGallery>
      </Lightbox>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { ImageGallery, Lightbox } from '@russmedia-frontend/np-common'
import { computed, ref } from 'vue'

const props = defineProps<{
  block: Block
}>()

const showLightbox = ref(false)
const activeIndex = ref(0)

const title = computed(() => {
  return (props.block.a && props.block.a.length > 0) ? props.block.a?.find((attr: BlockAttribute) => attr.key === 'galleryTitle')?.value || '' : ''
})

const figures = computed(() => {
  const content = props.block.h
  const figureRegex = /<figure[^>]*>.*?<\/figure>/g
  return content?.match(figureRegex) || []
})

const slidesTotal = computed(() => figures.value.length)

function onSlideChange(index: number) {
  activeIndex.value = index - 1
}
</script>
