<template>
  <section class="relative overflow-hidden" :class="`gdpr-block--${provider}`">
    <Video v-if="isVideoBlock" />
    <Embed v-else />
    <div
      class="absolute top-0 flex flex-col h-full p-3 sm:pt-8 sm:px-8 lef-0 overflow"
      :class="{ 'sm:pb-16': isVideoBlock }"
    >
      <h4 class="typo-teaser-4">
        Ja, ich möchte {{ isVideoBlock ? 'Videos' : 'Inhalte' }} von
        {{ getProviderDetails(provider as GranularConsent).name }} angezeigt bekommen.
      </h4>
      <div class="flex gap-4 pt-2 pb-5 grow-[2] items-end">
        <DefaultButton
          type="TransparentYellow"
          @click="emit('changeGdpr', '*')"
        >
          <span>Alles einblenden</span>
        </DefaultButton>
        <DefaultButton @click="emit('changeGdpr', provider)">
          <!-- provider icon here -->
          <span>Jezt einblenden</span>
        </DefaultButton>
      </div>
      <span class="typo-meta-2">
        Ich bin einverstanden, dass mir externe Inhalte angezeigt werden. Damit
        können personenbezogene Daten an Drittplattformen übermittelt werden.
        <a
          class="underline text-text"
          target="_blank"
          href="https://nnp.vol.at/datenschutzerklaerung"
        >
          Mehr dazu in unserer Datenschutzerklärung.
        </a>
      </span>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { GranularConsent } from '@russmedia-frontend/nordstern-embeds'
import { getProviderDetails } from '@russmedia-frontend/nordstern-embeds'
import { DefaultButton } from '@russmedia-frontend/np-common'
import Embed from '../assets/svg/Consent/Embed.svg'
import Video from '../assets/svg/Consent/Video.svg'
import { useGdpr } from '../composables/useGdpr'

const props = withDefaults(
  defineProps<{
    provider?: string
  }>(),
  {
    provider: '_generic',
  },
)

const emit = defineEmits<{
  (event: 'changeGdpr', provider: string): void
}>()

const isVideoBlock = useGdpr().isVideoProvider(props.provider)
</script>
