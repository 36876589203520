<template>
  <div v-if="src" class="aspect-video">
    <iframe
      :src="src"
      class="relative w-full h-full"
      frameborder="0"
      allowfullscreen
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    data?: any[]
  }>(),
  {
    data: () => [],
  },
)

const src = computed(
  () => {
    if (props.data?.length > 0) {
      return props.data.find((item: any) => item.key === 'url')?.value ?? ''
    }

    return ''
  },
)
</script>
