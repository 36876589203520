<template>
  <SnowReport :items="parsedData" />
</template>

<script setup lang="ts">
import { SnowReport } from '@russmedia-frontend/np-common'
import { onMounted, ref } from 'vue'

const props = defineProps<{
  block: Block
  content?: string
}>()

const blockString = props.block.h
const parsedData = ref<SnowReport[]>([])

onMounted(() => {
  const container = document.createElement('div')
  container.innerHTML = blockString as string

  const scriptTag = container.querySelector('script#snowReportData')
  const jsonData = scriptTag?.textContent

  if (jsonData) {
    parsedData.value = JSON.parse(jsonData)
  }
})
</script>
