import type { GdprConsent } from '@russmedia-frontend/nordstern-embeds'
import type { Ref } from 'vue'
import { GranularConsent } from '@russmedia-frontend/nordstern-embeds'
import { ref } from 'vue'

const gdprSettings = ref({
  necessary: false,
  preferences: false,
  marketing: false,
  statistics: false,
  granular: {},
}) as Ref<GdprConsent>

export function useGdpr() {
  return {
    getSettings: () => gdprSettings,
    setSettings,
    isProviderEnabled,
    isVideoProvider,
  }
}

function setSettings(newSettings: GdprConsent) {
  gdprSettings.value = newSettings
}

function isProviderEnabled(provider: string): boolean {
  return gdprSettings.value?.granular
    ? provider in gdprSettings.value.granular
    : false
}

function isVideoProvider(provider: string): boolean {
  if (!provider) { return false }

  return [
    GranularConsent.youtube,
    GranularConsent.apa,
    GranularConsent.vimeo,
    GranularConsent.vi,
    GranularConsent.glomex,
    GranularConsent.brightcove,
    GranularConsent.player,
  ].includes(provider as GranularConsent)
}
