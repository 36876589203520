<template>
  <BrightcovePlayer :item="item" />
</template>

<script setup lang="ts">
import { BrightcovePlayer } from '@russmedia-frontend/np-common'
import { computed } from 'vue'

const props = defineProps<{
  attrs?: BlockAttribute[]
}>()

function findAttributeValue(key: string) {
  if (props.attrs && props.attrs.length > 0) {
    return props.attrs?.find((attr: BlockAttribute) => attr.key === key)?.value
  }

  return ''
}

const item = computed(() => {
  const isPlaylist = findAttributeValue('isPlaylist') ?? false

  return {
    id: findAttributeValue('videoId') ?? '',
    isPlaylist,
    accountId: '6292321027001',
    playerId: isPlaylist ? 'eTWiPtoxJ' : 'default',
    embedId: 'default',
  } as BrightcovePlayerItem
})
</script>
