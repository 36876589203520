<template>
  <div
    v-if="content"
    ref="htmlBlock"
    @click="emit('blockClicked', block, $event)"
  />
</template>

<script setup lang="ts">
import { onMounted, useTemplateRef } from 'vue'

const { content } = defineProps<{
  block: Block
  content?: string
}>()

const emit = defineEmits<{
  (event: 'blockClicked', block: Block, ev: MouseEvent): void
}>()

const htmlBlock = useTemplateRef('htmlBlock')

onMounted(() => {
  if (content && htmlBlock.value) {
    const scriptEl = document.createRange().createContextualFragment(content)
    htmlBlock.value?.appendChild(scriptEl)
  }
})
</script>
