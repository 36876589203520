export function useSubscription() {
  return {
    isPremiumUser: (abo = {} as any) => {
      if (
        abo.hasPlusAccess
        || abo.hasPlusPremiumAccess
        || document.referrer.match(/https:\/\/www.(vn|neue)\.at/i)
      ) {
        return true
      }

      return false
    },
  }
}
